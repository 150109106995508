.app {
background-color: pink;
}

h1{
  background-color: pink;
  position : absolute;
  font-family: "Montserrat", sans-serif;
}

.heading-loading{
  font-size: x-large;
  color: black;
}

#clickme {
  margin-top: 20px;
  margin-left: 100px;
  border: 1px solid black;
  cursor: pointer;
}


button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #fd1853;
  font-family: "Montserrat", sans-serif;
  overflow: hidden;
  border: none;
}

button:after {
  content: " ";
  width: 0%;
  height: 100%;
  background: #fff;
  position: absolute;
  transition: all 0.4s ease-in-out;
  right: 0;
}

button:hover::after {
  right: auto;
  left: 0;
  width: 100%;
}

button span {
  text-align: center;
  text-decoration: none;
  width: 100%;
  padding: 18px 25px;
  color: #fffcfd;
  font-size: 1.125em;
  font-weight: 700;
  letter-spacing: 0.3em;
  z-index: 20;
  transition: all 0.3s ease-in-out;
}

button:hover span {
  animation: scaleUp 0.3s ease-in-out;
  color: #fd1853;
}

button:hover {
  animation: scaleUp 0.3s ease-in-out;
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

.star-1 {
  position: absolute;
  top: 20%;
  left: 20%;
  width: 25px;
  height: auto;
  filter: drop-shadow(0 0 0 #fd1853);
  z-index: -5;
  transition: all 1s cubic-bezier(0.05, 0.83, 0.43, 0.96);
}

.star-2 {
  position: absolute;
  top: 45%;
  left: 45%;
  width: 15px;
  height: auto;
  filter: drop-shadow(0 0 0 #fd1853);
  z-index: -5;
  transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
}

.star-3 {
  position: absolute;
  top: 40%;
  left: 40%;
  width: 5px;
  height: auto;
  filter: drop-shadow(0 0 0 #fd1853);
  z-index: -5;
  transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
}

.star-4 {
  position: absolute;
  top: 20%;
  left: 40%;
  width: 8px;
  height: auto;
  filter: drop-shadow(0 0 0 #fd1853);
  z-index: -5;
  transition: all .8s cubic-bezier(0, 0.4, 0, 1.01);
}

.star-5 {
  position: absolute;
  top: 25%;
  left: 45%;
  width: 15px;
  height: auto;
  filter: drop-shadow(0 0 0 #fd1853);
  z-index: -5;
  transition: all .6s cubic-bezier(0, 0.4, 0, 1.01);
}

.star-6 {
  position: absolute;
  top: 5%;
  left: 50%;
  width: 5px;
  height: auto;
  filter: drop-shadow(0 0 0 #fd1853);
  z-index: -5;
  transition: all .8s ease;
}

button:hover .star-1 {
  position: absolute;
  top: -2%;
  left: 0%;
  width: 25px;
  height: auto;
  filter: drop-shadow(0 0 10px #fd1853);
  z-index: 2;
}

button:hover .star-2 {
  position: absolute;
  top: 34%;
  left: 41.5%;
  width: 15px;
  height: auto;
  filter: drop-shadow(0 0 10px #fd1853);
  z-index: 2;
}

button:hover .star-3 {
  position: absolute;
  top: 55%;
  left: 25%;
  width: 5px;
  height: auto;
  filter: drop-shadow(0 0 10px #fd1853);
  z-index: 2;
}

button:hover .star-4 {
  position: absolute;
  top: 0%;
  left: 55%;
  width: 8px;
  height: auto;
  filter: drop-shadow(0 0 10px #fd1853);
  z-index: 2;
}

button:hover .star-5 {
  position: absolute;
  top: 25%;
  left: 85%;
  width: 15px;
  height: auto;
  filter: drop-shadow(0 0 10px #fd1853);
  z-index: 2;
}

button:hover .star-6 {
  position: absolute;
  top: 75%;
  left: 60%;
  width: 5px;
  height: auto;
  filter: drop-shadow(0 0 10px #fd1853);
  z-index: 2;
}

.continue {
  cursor: pointer;
  font-weight: 700;
  font-family: Helvetica,"sans-serif";
  transition: all .2s;
  padding: 10px 20px;
  border-radius: 100px;
  background: white;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 15px;
}

.continue:hover {
  background: white;
}

.continue > svg {
  width: 34px;
  margin-left: 10px;
  transition: transform .3s ease-in-out;
}

.continue:hover svg {
  transform: translateX(5px);
}

.continue:active {
  transform: scale(0.95);
}

